import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutUs from "./components/AboutUs";
import AboutUsTwo from "./components/AboutUs/AboutUsTwo";
import Contact from "./components/Contact";
import Error from "./components/Error";
import Loader from "./components/Helper/Loader";
import ScrollToTop from "./components/Helper/ScrollToTop";
import Home from "./components/Home";
import Maintenance from "./components/Maintenance";
import News from "./components/News";
import SingleNews from "./components/News/SingleNews";
import Service from "./components/Service";

function Routes() {
  const [loading, setLoading] = useState(true);
  const [maintenance, setMaintenance] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });
  return (
    <>
      {loading && (
        <div className={`appie-loader ${loading ? "active" : ""}`}>
          <Loader />
        </div>
      )}
      <div className={`appie-visible ${loading === false ? "active" : ""}`}>
        <Router>
          <ScrollToTop>
            <Switch>
              {maintenance ? (
                <>
                  <Route exact path="/" component={Maintenance} />
                </>
              ) : (
                <>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/news" component={News} />
                  <Route
                    exact
                    path="/news/single-news"
                    component={SingleNews}
                  />
                  <Route exact path="/service" component={Service} />
                  <Route exact path="/about-us" component={AboutUs} />

                  <Route exact path="/contact" component={Contact} />
                  
                  <Route
                    exact
                    path="/about-us-another"
                    component={AboutUsTwo}
                  />
                  <Route component={Error} />
                </>
              )}
            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    </>
  );
}

export default Routes;
