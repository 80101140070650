import React from "react";

function HeroMaintenance() {
  async function submit() {
    
  }
  return (
    <>
      <div className="appie-about-top-title-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="appie-about-top-title">
                <h2 className="title">Currently Under Maintenance</h2>
                <p>
                  Please check back soon. Enter your email below to get updates
                  about our launch.
                </p>
                <span className="input">
                  <input placeholder="Enter Your Email Address" />
                  <button onClick={submit} className="btn btn-primary">
                    SUBMIT
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default HeroMaintenance;
