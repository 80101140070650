import React from "react";

function FooterMaintenance({ className }) {
  return (
    <>
      <section className={`maintenance-footer`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="maintenance-footer-copyright">
                
                <div className="copyright-text">
                  <p>Copyright © 2022 PROPS. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterMaintenance;
