import React, { useEffect } from "react";
import logo from "../../assets/images/props-logo.svg";
import StickyMenu from "../../lib/StickyMenu";

function HeaderMaintenance({ action }) {
  useEffect(() => {
    StickyMenu();
  });
  return (
    <>
      <header className="appie-header-area appie-sticky">
        <div className="container">
          <div className="header-nav-box">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                <div className="appie-logo-box">
                  <a href="/">
                    <img src={logo} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderMaintenance;
