import React from "react";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import Drawer from "../Mobile/Drawer";
import FooterMaintenance from "./FooterMaintenance";
import HeaderMaintenance from "./HeaderMaintenance";
import HeroMaintenance from "./HeroMaintenance";

function Maintenance() {
  const [drawer, drawerAction] = useToggle(false);

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HeaderMaintenance drawer={drawer} action={drawerAction.toggle} />
      <HeroMaintenance />
      <FooterMaintenance />
      <BackToTop />
      
    </>
  );
}

export default Maintenance;
